document.addEventListener('DOMContentLoaded', () => {
  // For login details form
  const loginDetailsForm = document.querySelector('.myaccount-login-details-form')
  const loginDetailsButtons = document.querySelector('.myaccount-login-details-buttons')
  const emailEditButton = document.querySelector('.myaccount-login-details-form .email-inputfield .edit-button')
  const passwordEditButton = document.querySelector('.myaccount-login-details-form .password-inputfield .edit-button')
  const loginDetailsCancelButton = loginDetailsButtons?.querySelector('.cancel-button')

  const emailInputfield = document.querySelector('.myaccount-login-details-form .email-inputfield input')
  const passwordFields = document.querySelectorAll('.myaccount-inputfield.oldpassword-inputfield input, .myaccount-inputfield.newpassword-inputfield input, .myaccount-inputfield.confirmpassword-inputfield input')

  emailEditButton?.addEventListener('click', () => {
    // to not activate both form at the same time
    loginDetailsForm.classList.remove('password-form-active')

    emailInputfield.removeAttribute('readonly')
    loginDetailsForm.classList.add('email-form-active')
    passwordFields.forEach((passwordField) => {
      passwordField.setAttribute('disabled', true)
    })
  })

  passwordEditButton?.addEventListener('click', () => {
    loginDetailsForm.classList.add('password-form-active')
    passwordFields.forEach((passwordField) => {
      passwordField.removeAttribute('disabled')
    })
  })

  loginDetailsCancelButton?.addEventListener('click', () => {
    loginDetailsForm.reset()
    emailInputfield.setAttribute('readonly', true)
    loginDetailsForm.classList.remove('email-form-active', 'password-form-active')
  })


  // For personal details form
  const personalDetailsForm = document.querySelector('.myaccount-personal-details-form')
  const personalDetailsEditButtons = personalDetailsForm?.querySelectorAll('.edit-button')
  const personalDetailsCancelButton = document.querySelector('.myaccount-personal-details-buttons .cancel-button')

  const firstNameInputfield = document.querySelector('.myaccount-personal-details-form .first-name-inputfield input')
  const lastNameInputfield = document.querySelector('.myaccount-personal-details-form .last-name-inputfield input')

  personalDetailsEditButtons?.forEach((personalDetailsEditButton) => {
    personalDetailsEditButton?.addEventListener('click', () => {
      personalDetailsForm.classList.add('active')
      firstNameInputfield.removeAttribute('readonly')
      lastNameInputfield.removeAttribute('readonly')
    })
  })

  personalDetailsCancelButton?.addEventListener('click', () => {
    personalDetailsForm.reset()
    personalDetailsForm.classList.remove('active')
    firstNameInputfield.setAttribute('readonly', true)
    lastNameInputfield.setAttribute('readonly', true)
  })

  // For error state
  document.querySelector('.general-error-message')?.scrollIntoView({ behavior: 'smooth' })
})